.library {
	position: fixed;
	top: 70px;
	margin-left: 10px;
	width: 10%;
	border: 1px solid #959595;
	border-radius: 0.5em;
	padding: 0.1em 0.3em;
	overflow-y: auto;
	max-height: 70%;
}
