.catalog-root {
	max-width: 1400px;
	padding: 10px;
}

.catalog-row-info {
	margin-left: 10px;
	margin-right: 10px;
}

.catalog-link {
	text-decoration-line: underline;
	color: blue;
	float: left;
}

.catalog-dwg-number {
	text-decoration-line: underline;
	color: blue;
}

.catalog-block {
	border: 1px solid #959595;
	border-radius: 0.5em;
	padding: 0.1em 0.3em;
	margin: 5px;
}

.catalog-header {
	color: blue;
}
