.Account-root {
	max-width: 1200px;
	padding: 10px;
}
.info-row {
	width: 70%;
	font-size: 18px;
}
.password-box {
	border: solid 1px black;
	padding: 10px;
}
li {
	margin: 10px;
	padding: 0;
}

.Account-root input {
	margin: 10px;
	width: 40%;
}
.Account-root p {
	margin: 10px;
}
